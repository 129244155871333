import React from 'react';


const HomePage = () => {
    return (
        <div className="table bg-center bg-cover w-full h-screen bg-[url('./images/background.avif')]">
            <div className="table-cell align-bottom pl-4 md:pl-8 lg:pl-20 pb-24 md:pb-4">
                <h1 className="text-7xl md:text-9xl font-bold text-white mb-4 md:mb-10">Jagpoint</h1>
                <h2 className="text-2xl md:text-4xl font-bold text-white mb-7 max-w-3xl">Specialist in Jaguar & Land Rover voor onderhoud, reparatie & revisie</h2>
            </div>
        </div>
    )
}

export default HomePage