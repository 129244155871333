import React from 'react';
import image from '../images/jagpoint_2.avif'


const Expertise = () => {
    return (
        <div className="flex pb-8 md:pb-24 px-4 md:px-8">
            <div className='flex flex-col md:flex-row max-w-[1200px] mx-auto justify-center md:space-x-10'>
                <div className="w-6/6 md:w-3/6 flex flex-col justify-center space-y-4">
                    <h1 className="text-4xl md:text-5xl font-bold">Toegewijde expertise</h1>
                    <p className='text-lg'>Van de tijdloze en elegante E-Type tot de meer recentere modellen begrijpen en waarderen wij de techniek van Jaguar & Land Rover.
                    <br></br>
                    <br></br>
                    Onze service beperkt zich ook niet tot de auto zelf. De bezitter weet zich bij ons ook vakkundig behandeld. We streven naar een hoog serviceniveau. 
                    Met duidelijke prijsopgaven, realistische planningen en altijd tijd voor een lekker kopje koffie.</p>
                </div>
                <div className="w-6/6 md:w-3/6 flex justify-center">
                    <img className="w-full h-[400px] object-cover mt-4 md:mt-0" src={image} alt="Expertise on jaguar and land rover"/>
                </div>
            </div>
        </div>
    )
}

export default Expertise