import React from 'react';
import { MdMailOutline } from "react-icons/md";
import { FiHome, FiPhone, FiClock } from "react-icons/fi";
import logo from '../images/logo.png'

const Contact = () => {

    return (

            <section id='contact' className="flex flex-col bg-black pt-12 md:pt-24 pb-12 px-4 md:px-8">
                <div className='flex flex-col md:flex-row max-w-[1200px] w-full md:w-[700px] lg:w-[1000px] mx-auto'>
                    <div className="w-6/6 md:w-3/6 flex flex-col space-y-8 pl-6 border-s-2">
                        <h1 className="text-5xl font-bold text-white">Jagpoint</h1>
                        <div className='flex flex-row space-x-4'>
                            <MdMailOutline className='text-white h-7 w-7' />
                            <p className='text-lg text-white'>info@jagpoint.nl </p>
                        </div>
                        <div className='flex flex-row space-x-4'>
                            <FiHome className='text-white h-7 w-7' />
                            <p className='text-lg text-white'>Weltevreden 24D <br></br> 3731 AL De Bilt </p>
                        </div>
                        <div className='flex flex-row space-x-4'>
                            <FiPhone className='text-white h-7 w-7' />
                            <a href="tel:(0346) 35 02 10" className='text-lg text-white underline'>0346-350210</a>
                        </div>
                        <div className='flex flex-row space-x-4'>
                            <FiClock className='text-white h-7 w-7' />
                            <p className='text-lg text-white'>
                                Maandag tot vrijdag: 08:00 - 17:00
                                <br></br>
                                Zaterdag: Gesloten
                                <br></br>
                                Zondag: Gesloten
                            </p>
                        </div>
                    </div>
                    <div className="w-6/6 md:w-3/6 mt-4 md:mt-0 flex justify-center">
                    <iframe
                        loading='lazy'
                        title='map-jagpoint'
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2450.078306790529!2d5.1716163157924075!3d52. 1147039797395!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c668e40233497d%3A0x194297f83aeedad8!2sWeltevreden%2024d%2C%203731%20AL%20De%20Bilt!5e0!3m2!1snl!2snl!4v1603278710781!5m2!1snl!2snl"
                        width="600px"
                        height="400px"
                        aria-hidden="false"
                    ></iframe>
                    </div>
                </div>
            <img src={logo} className="mt-8 md:mt-12 mx-auto h-[75px] md:h-[100px]" alt="logo"/>
        </section>
    )
}

export default Contact