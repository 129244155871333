import React, { useState } from 'react';
import Botpoison from "@botpoison/browser";

interface errorMsg {
  id: number
  msg: string
}

const FORMSPARK_ACTION_URL = `https://submit-form.com/${process.env.REACT_APP_FORMSPARK_CODE}`;
const botpoison = new Botpoison({
  publicKey: process.env.REACT_APP_BOTPOISON_CODE as string
});

const Form = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [telephone, setTelephone] = useState('')
  const [plate, setPlate] = useState('')
  const [message, setMessage] = useState('')
  const [info, setInfo] = useState('')
  const [error, setError] = useState<errorMsg[]>([])
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const toInputUppercase = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };

  const handleSubmit = async (e:any) => {

    if (!name) {
      setError( // Replace the state
        [ // with a new array
          { id: 1, msg: 'Geen naam ingevuld' } // and one new item at the end
        ]
      )


      return
    }
    if (!email) {
      setError( // Replace the state
        [ // with a new array
          { id: 2, msg: 'Geen email ingevuld' } // and one new item at the end
        ]
      )
    
      return
    }
    if (!telephone) {
      setError( // Replace the state
      [ // with a new array
        { id: 3, msg: 'Geen telefoon ingevuld' } // and one new item at the end
      ]
    )

      return
    }
    if (!message) {
      setError( // Replace the state
      [ // with a new array
        { id: 4, msg: 'Geen bericht ingevuld' }// and one new item at the end
      ]
    )
     

      return
    }

    setError([])
    setLoading(true)

    e.preventDefault();
    const { solution } = await botpoison.challenge();
    await fetch(FORMSPARK_ACTION_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        name,
        email,
        telephone,
        plate,
        message,
        _botpoison: solution,
      }),
    });

    setFormSubmitted(true)
    setLoading(false)
  }


  return (
    <section id='form' className='flex justify-center items-center mt-12 md:mt-24 mb-12 md:mb-24 px-4 md:px-8'>
      <div className='flex max-w-[1200px] flex-col'>
        <h1 className="text-4xl md:text-5xl font-bold text-center">Contact ons</h1>
        {formSubmitted ?
        <p className='text-2xl mt-4 text-center'>Dankjewel voor uw bericht u krijgt een bevesting in uw mail!</p>
        :
        <>
        {error.length > 0 && <p className="text-md mt-8 text-red-600 font-medium">{error[0]?.msg}</p>}
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 mt-8">
            <input onChange={e => setName(e.target.value)} placeholder="Naam" type="text" id="name" className={`${error.some(error => error.id === 1) ? 'border-red-500' : 'border-gray-300'} bg-gray-50 border text-gray-900 text-md rounded-lg block w-full md:w-[300px] p-2.5`} />
            <input onChange={e => setEmail(e.target.value)} placeholder="Email" type="text" id="email" className={`${error.some(error => error.id === 2) ? 'border-red-500' : 'border-gray-300'} bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full md:w-[300px] p-2.5`} />
          </div>
          <div className="flex flex-row space-x-2 md:space-x-4 mt-4">
            <input onChange={e => setTelephone(e.target.value)} placeholder="Telefoonnummer" type="text" inputMode='numeric' id="phone-number" className={`${error.some(error => error.id === 3) ? 'border-red-500' : 'border-gray-300'} bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block w-full p-2.5`} />
            <input onChange={e => setPlate(e.target.value)} onInput={toInputUppercase} placeholder="Kenteken (Optioneel)" type="text" id="license-plate" className="bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg block p-2.5" />
          </div>
          <div className="flex flex-row space-x-4 mt-4">
            <textarea onChange={e => setMessage(e.target.value)} placeholder="Bericht" id="message" className={`${error.some(error => error.id === 4) ? 'border-red-500' : 'border-gray-300'} bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg w-full h-[200px] min-h-[40px] max-h-[200px] block p-2.5`} />
          </div>
          <div className='hidden'>
            <input onChange={e => setInfo(e.target.value)} value={info} placeholder="Info" id="info" />
          </div>
          <button onClick={handleSubmit} disabled={loading || info ? true: false} className={`mt-4 ${loading ? 'bg-gray-200' : 'bg-black'} text-white p-2 w-full font-semibold`}>{loading ? 'Laden...' : 'Verstuur'}</button>
        </>
        }
      </div>
    </section>
  )
}

export default Form