import React from 'react';
import image from '../images/jagpoint_5.avif'


const Services = () => {
    return (
        <section id='services' className="flex pt-8 md:pt-24 pb-8 md:pb-24 px-4 md:px-8">
            <div className='flex flex-col-reverse md:flex-row max-w-[1200px] mx-auto justify-center md:space-x-10'>
                <div className="w-6/6 md:w-3/6 flex justify-center">
                    <img className="w-full mt-4 md:mt-0 h-[400px] object-cover" src={image} alt="Services on jaguar and land rover"/>
                </div>
                <div className="w-6/6 md:w-3/6 flex flex-col justify-center space-y-4">
                    <h1 className="text-4xl md:text-5xl font-bold">Onderhoud, reparatie & revisie</h1>
                    <p className='text-lg'>Welkom bij Jagpoint. Wij bedienen liefhebbers van klassieke en exclusieve auto's van het merk Jaguar & Land Rover. 
                    <br></br>
                    <br></br>
                    Van deze auto's beschikken wij over de merkspecifieke kennis en wij leveren hoogwaardige service.</p>
                </div>
            </div>
        </section>
    )
}

export default Services