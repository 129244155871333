import React, { useState } from 'react';
import logo from '../images/logo.png'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { TfiMenu, TfiClose } from "react-icons/tfi";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
        <div className='flex flex-col absolute top-5 px-4 md:hidden w-full'>
            <div className='flex flex-row justify-between w-full'>
                <img src={logo} className="h-[75px] w-auto" alt="logo" />
                <div className='flex items-center'>
                    <TfiMenu onClick={() => setIsOpen(!isOpen)} className={`${isOpen ? 'hidden' : 'block'} cursor-pointer text-white h-7 w-7`} />
                    <TfiClose onClick={() => setIsOpen(!isOpen)} className={`${isOpen ? 'block' : 'hidden'} cursor-pointer text-white h-5 w-5`} />
                </div>
            </div>
            {isOpen &&
                <div className='flex flex-col px-4 mt-12 pb-4 space-y-4 border-b-4 border-white'>
                    <AnchorLink className='text-white text-3xl font-bold' href='#services'>Onze diensten</AnchorLink>
                    <AnchorLink offset={() => 20} className='text-white text-3xl font-bold' href='#gallery'>Galerij</AnchorLink>
                    <AnchorLink offset={() => 80} className='text-white text-3xl font-bold' href='#form'>Contact ons</AnchorLink>
                    <AnchorLink className='text-white text-3xl font-bold' href='#contact'>Contactgegevens</AnchorLink>
                </div>
            }
        </div>
        <div className='hidden md:flex absolute top-5 pl-4 lg:pl-20 w-full flex-row space-x-4 lg:space-x-12'>
            <img src={logo} className="h-[75px]" alt="logo"/>
                <div className='flex flex-row items-center space-x-12'>
                    <AnchorLink className='text-2xl text-white font-light hover:underline' href='#services'>Onze diensten</AnchorLink>
                    <AnchorLink offset={() => 20} className='text-2xl text-white font-light hover:underline' href='#gallery'>Galerij</AnchorLink>
                    <AnchorLink offset={() => 80} className='text-2xl text-white font-light hover:underline' href='#form'>Contact ons</AnchorLink>
                    <AnchorLink className='text-2xl text-white font-light hover:underline' href='#contact'>Contactgegevens</AnchorLink>
                </div>
        </div>
        </>
    )
}

export default Navbar