import React from 'react';
import './App.css';
import HomePage from './components/HomePage';
import Services from './components/Services';
import Expertise from './components/Expertise';
import Gallery from './components/Gallery';
import Form from './components/Form';
import Contact from './components/Contact';
import Navbar from './components/Navbar';

function App() {
  return (
    <>
    <Navbar />
    <HomePage />
    <Services />
    <Expertise />
    <Gallery />
    <Form />
    <Contact />
    </>
  );
}

export default App;
