import React from 'react';

import image1 from '../images/jagpoint_8.avif'
import image2 from '../images/jagpoint_9.avif'
import image3 from '../images/jagpoint_3.avif'
import image4 from '../images/jagpoint_4.avif'
import image5 from '../images/jagpoint_6.avif'
import image6 from '../images/jagpoint_7.avif'
import image10 from '../images/jagpoint_10.avif'
import image11 from '../images/jagpoint_11.avif'
import Slider from "react-slick";


const Gallery = () => {

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,  
      };

    return (
        <section id='gallery' className="flex flex-col justify-start space-y-8 items-center px-4 md:px-8">
            <h1 className="text-4xl md:text-5xl font-bold">Galerij</h1>
            <div className="hidden md:grid max-w-[1000px] grid-flow-row-dense grid-cols-5 grid-rows-3 gap-4">
                <div className="col-span-2">
                    <img src={image1} className="object-cover w-full h-[300px]" alt="jagpoint inside red car"/>
                </div>
                <div className="col-span-3">
                    <img className="object-cover w-full h-[300px]" src={image2} alt="jagpoint front of red car"/>
                </div>
                <div className="col-span-3">
                    <img src={image3} className="object-cover w-full h-[300px]" alt="jagpoint work space"/>
                </div>
                <div className="col-span-2">
                    <img className="object-cover w-full h-[300px]" src={image4} alt="jagpoint engine of yellow car"/>
                </div>
                <div className="col-span-2">
                    <img src={image5} className="object-cover w-full h-[300px]" alt="jagpoint yellow car interior"/>
                </div>
                <div className="col-span-3">
                    <img className="object-cover w-full h-[300px]" src={image6} alt="jagpoint yellow car"/>
                </div>
                <div className="col-span-3">
                    <img src={image11} className="object-cover w-full h-[300px]" alt="jaguar unique hatchback front"/>
                </div>
                <div className="col-span-2">
                    <img className="object-cover w-full h-[300px]" src={image10} alt="jaguar unique hatchback back"/>
                </div>
            </div>
            <div className='md:hidden w-full overflow-hidden'>
                <Slider {...settings}>
                    <div>
                        <img src={image1} className="object-cover w-full h-[400px]" alt="jagpoint inside red car"/>
                    </div>
                    <div>
                        <img className="object-cover w-full h-[400px]" src={image2} alt="jagpoint front of red car"/>
                    </div>
                    <div>
                        <img src={image3} className="object-cover w-full h-[400px]" alt="jagpoint work space" />
                    </div>
                    <div>
                        <img className="object-cover w-full h-[400px]" src={image4} alt="jagpoint engine of yellow car" />
                    </div>
                    <div>
                        <img src={image5} className="object-cover w-full h-[400px]" alt="jagpoint yellow car interior" />
                    </div>
                    <div>
                        <img className="object-cover w-full h-[400px]" src={image6} alt="jagpoint yellow car" />
                    </div>
                    <div>
                        <img className="object-cover w-full h-[400px]" src={image10} alt="jagpoint yellow car" />
                    </div>
                    <div>
                        <img className="object-cover w-full h-[400px]" src={image11} alt="jagpoint yellow car" />
                    </div>
                </Slider>
            </div>
        </section>
    )
}

export default Gallery